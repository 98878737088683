
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$crypto-casino-admin-panel-primary: mat-palette($mat-indigo);
$crypto-casino-admin-panel-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$crypto-casino-admin-panel-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$crypto-casino-admin-panel-theme: mat-light-theme((
  color: (
    primary: $crypto-casino-admin-panel-primary,
    accent: $crypto-casino-admin-panel-accent,
    warn: $crypto-casino-admin-panel-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($crypto-casino-admin-panel-theme);



/* You can add global styles to this file, and also import other style files */
@import "~ngx-toastr/toastr";

.text-align-end {
    text-align: end !important;
}
.text-danger {
    color: #dc3545 !important;
    font-size: 14px;
}
.table-no-record {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}
.modal-header-custm {
    padding: 0px;
}
.tabel-body-checkbox {
    float: left;
    margin: 3px 0px 0px 11px;
}

.view-label-align label {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
}

.content-view {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}


@media (max-width: 1024px) {
    .header-right-part {
        padding-top: 0px;
    }
}
@media (width: 1024px) {
    .header-padding {
        padding-top: 8px;
    }
}

.view-block {
    max-width: 600px;
    margin: 0 auto;
}

.header-right-icons {
    color: #012970;
    font-size: 30px;
    .icon-contain {
        margin-right: 15px;
        .fa {
            margin-right: 12px;
        }
    }
}

// country code with mobile number
.phone-number-input-box {
    display: flex;
}
.phone-number-input-box select {
    
    width: 60px;
    padding: 4px;
}

// search and filter
.btn_search_group {
    border: 1px solid #ccc;
    background: #fff;
    border-left: none;
    padding-left: 0;
    
}

// card start
.title {
    margin-bottom: 50px;
    text-transform: uppercase;
}

.card-block {
    font-size: 1em;
    position: relative;
    margin: 0;
    padding: 1em;
    border: none;
    
    box-shadow: none;
    text-align: end;
    .card-btn {
        background-color: #012970;
        color: #fff;
        font-size: 15px;
        width: 100%;
        border-radius: 4px;
    }
    .card-btn:hover {
        background: #222;
    }
}
.card {
    font-size: 1em;
    overflow: hidden;
    padding: 5;
    border: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;

    background-image: linear-gradient(to top, #96fbc4 0%, #012970 100%);
    color: #fff;
    margin-bottom: 30px;
    .card-title {
        text-align: center;
        margin-top: 0.75rem;
    }
    .card-left-image {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}


// lottery table circular box
.lottery-number-box {
    display: flex;
    justify-content: space-evenly;
}

.circle {
    min-width: 20px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    text-align: center;
    vertical-align: baseline;
    color: #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
.rounded-corner-box {
    min-width: 60px;
    border-radius: 10px;
    padding: 1em 1em;
    font-weight: 700;
    text-align: center;
    vertical-align: baseline;
    color: #fff;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.red-circle {
    background-color: red;
}

.bonus-num-bg-color{
    background-color: #012970;
}

// end lottery table circular box

// pagination
.ngx-pagination .current {
    background: #012970 !important;
    //   border: transparent !important;
    border-radius: 0.25rem !important;
}

.ngx-pagination .current:hover {
    background: #222 !important;
}
// end pagination

.table-action-btn-alignment{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;

}


.mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0px 0.75em 0 0.75em;
    
    position: relative;
    height: 47px;
   
    border-radius: 50% !important;
}


.mat-form-field-appearance-outline .mat-form-field-label {
    top: 1.84375em;
    margin-top: -8px !important;
}
.mat-form-field-appearance-outline .mat-form-field-prefix, .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.30em !important;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.45em 0 1em 0;
}

.mat-form-field {
    display: block;
    position: relative;
    text-align: left;
 
    height: 43px;
    width: 100%;
    border-radius: 50px;
}
.mat-form-field-infix {
    display: block;
    position: relative;
    flex: auto;
    min-width: 0;

    height: 47px;
}
@media (max-width:767px) {
    .mat-form-field {
        display: block;
        position: relative;
        text-align: left;
   
        height: 53px;
        width:100%;
        border-radius: 50px;
        margin-top: 10px;
    }
}

table{
    min-width: 105px;
}

.page_title {
    color: #4f4b4b; 
    color: #012970;

    font-size: 28px;
    font-weight: bold;
 
    margin: 0px;
}

.h4, h4 {
    font-size: 1.5rem !important;
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem !important;
    font-weight: 500 !important;
    line-height: 1.2 !important;
}
h1, h2, h3, h4, h5, h6 {

    margin-bottom: .5rem !important;
}
.h5, h5 {
    font-size: 1.25rem !important;
}
.h6, h6 {
    font-size: 1rem !important;
}
.myinput{
    height: 43px !important;
    margin-top: 2px !important;
  }
  .Go_btn{
    height: 43px !important;
  }